section.results {
    td[axis=passing_grade] {
        text-align: center;
    }

    td[axis=attempt_number],
    td[axis=percentage_score],
    td[axis=score] {
        text-align: right
    }
    td[axis=duration_secs] {text-align: right}
}