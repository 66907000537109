/* sensible for 2022.. */
/* iphone 12/mini/SE */
.profile-page h1 {
  margin-bottom: 0;
}
.profile-page h1 + h2 {
  margin-top: 0;
  color: #666666;
}
@media screen and (max-width: 768px ) {
  .profile-page h1,
  .profile-page h1 + h2 {
    margin-left: 15px;
    margin-right: 15px;
  }
}
.profile-page .content {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 4px;
  max-width: 1000px;
}
@media screen and (max-width: 768px ) {
  .profile-page .content {
    display: flex;
    flex-direction: column;
    margin: 0 15px;
  }
}
.profile-page .content > section {
  background-color: var(--widget-background-color);
  border: 1px solid #eee;
  padding: 1em;
}
.profile-page .content > section > h2 {
  margin: 1ex 0;
}
.profile-page .content .results {
  grid-row: 2;
  grid-column: span 3;
}
.profile-page .edit {
  float: right;
}
@media screen and (max-width: 576px) {
  .profile-page .results > table th[scope=col][fieldname=score],
  .profile-page .results > table td[axis=score] {
    display: none;
  }
  .profile-page .results > table th[scope=col][fieldname=testleader],
  .profile-page .results > table td[axis=testleader] {
    display: none;
  }
  .profile-page .results > table th[scope=col][fieldname=duration_secs],
  .profile-page .results > table td[axis=duration_secs] {
    display: none;
  }
}
@media screen and (max-width: 375px) {
  .profile-page .results > table {
    width: 90%;
  }
  .profile-page .results > table th[scope=col][fieldname=percentage_score],
  .profile-page .results > table td[axis=percentage_score] {
    display: none;
  }
  .profile-page .results > table th[scope=col][fieldname=datetime_finished],
  .profile-page .results > table td[axis=datetime_finished] {
    width: 22%;
  }
  .profile-page .results > table th[scope=col][fieldname=assessment_name],
  .profile-page .results > table td[axis=assessment_name] {
    width: 50%;
  }
}
section.results td[axis=passing_grade] {
  text-align: center;
}
section.results td[axis=attempt_number],
section.results td[axis=percentage_score],
section.results td[axis=score] {
  text-align: right;
}
section.results td[axis=duration_secs] {
  text-align: right;
}
[name=new_password] + [name=new_password_confirm] {
  margin-top: 4px;
}
