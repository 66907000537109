@import "media";

.profile-page {

    h1 {
        margin-bottom: 0;
    }

    h1 + h2 {
        margin-top: 0;
        color: #666666;
    }

    @media @tablet {
        h1, h1+h2 {
            margin-left: 15px;
            margin-right: 15px;
        }
    }

    .content {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        gap: 4px;

        @media @tablet {
            display: flex;
            flex-direction: column;
            margin: 0 15px;
        }

        max-width: 1000px;

        > section {
            background-color: var(--widget-background-color);
            border: 1px solid #eee;
            //min-width: 400px;
            //max-width: 500px;
            padding: 1em;

            > h2 {
                margin: 1ex 0;
            }
        }

        .results {
            grid-row: 2;
            grid-column: span 3;
        }

    }

    .edit {
        float: right;
    }

    .results {
        @media @mobile {
            & > table {
                th[scope=col][fieldname=score], td[axis=score] {
                    display: none
                }

                th[scope=col][fieldname=testleader], td[axis=testleader] {
                    display: none
                }

                th[scope=col][fieldname=duration_secs], td[axis=duration_secs] {
                    display: none
                }
            }
        }
        @media @small-mobile {
            & > table {
                width: 90%;

                th[scope=col][fieldname=percentage_score], td[axis=percentage_score] {
                    display: none
                }

                //th[scope=col][fieldname=passing_grade], td[axis=passing_grade] {width: 30px;}
                th[scope=col][fieldname=datetime_finished], td[axis=datetime_finished] {
                    width: 22%;
                }

                th[scope=col][fieldname=assessment_name], td[axis=assessment_name] {
                    width: 50%;
                }
            }
        }
    }
}
